import { ConnectAccount } from "@coinbase/onchainkit/wallet";
import { base } from "viem/chains";
import { useAccount, useChainId, useConnect, useDisconnect } from "wagmi";
import { AccountDropdown } from "./AccountDropdown";
import { AccountInfoPanel } from "./AccountInfoPanel";
import { Avatar, Name } from "@coinbase/onchainkit/identity";

/**
 * AccountConnect
 *  - Connects to the wallet
 *  - Disconnects from the wallet
 *  - Displays the wallet network
 */
function AccountConnect() {
  const account = useAccount();
  const { status } = useConnect();
  const { disconnect } = useDisconnect();
  const chainId = useChainId();
  const { address } = useAccount();

  return (
    <div
      className="flex flex-grow"
      {...(status === "pending" && {
        "aria-hidden": true,
        style: {
          opacity: 0,
          pointerEvents: "none",
          userSelect: "none",
        },
      })}
    >
      {(() => {
        if (account.status === "disconnected" || account.status === "pending") {
          return <ConnectAccount className="text-3xl" />;
        }

        if (account.status === "connected" && chainId !== base.id) {
          return (
            <button onClick={() => disconnect()} type="button">
              Wrong network
            </button>
          );
        }
        
        return (
          <>
            <div className="flex flex-grow flex-col md:hidden">
              <AccountInfoPanel />
            </div>
            <div className="flex hidden md:block">
              <AccountDropdown />
            </div>
          </>
        );
      })()}
    </div>
  );
}

export default AccountConnect;
