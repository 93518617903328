const contractAddresses = {
  masterChef: "0x5Fad27EF54f843f77e96EdfA6C2AC3Aa89a89966",
  multiCall: "0x86FA85a252C8b2955b80bF9A7764cC65587f4D0e",
  zap: "0x391e6f3F1120B7180b233beb864e8Ab76ce60b48",
  router: "0xCa4EAa32E7081b0c4Ba47e2bDF9B7163907Fe56f",
  factory: "0x591f122D1df761E616c13d265006fcbf4c6d6551",
  wild: "0x2B23C79A1798F3380E3Cb2F9F69D552ED5d60D0D",
  weth: "0x4200000000000000000000000000000000000006",
  usdc: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
  nft: "0xCD2aA3A27fa0eDF880391C3846BE438e2A1E0B49",
  usdcLp: "0x6bee1580471F38000951abd788A9C060A4ad3Ac3",
  wildWethlp: "0xA79f899227105ABBC4e1F2A8d2391AA58386E4cD",
  presaleContract: "0xb65b2f5708fcB5841c184F1A5BC0c36d6aD57BFd",
  presaleFork: "0x5c5BC3C1b4132cbED8f2EEe31a12f42Cf9c612B8"
};

export default contractAddresses;