import React from "react";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { http, createConfig, WagmiProvider, useAccount } from "wagmi";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { base } from "wagmi/chains";
import { coinbaseWallet } from "wagmi/connectors";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { RefreshContextProvider } from "context/RefreshContext";
import ContractContextProvider from "context/contracts";
import { ThemeContextProvider } from "context/ThemeContext";
import { LanguageProvider } from "context/Localization";
import { ModalProvider } from "uikit";
import { OnchainKitProvider } from "@coinbase/onchainkit";

import store from "state";

const Providers = ({ children }) => {
  const queryClient = new QueryClient();

  const config = createConfig({
    chains: [base],
    connectors: [
      coinbaseWallet({
        appName: "TOKEN",
        preference: "smartWalletOnly",
      }),
    ],
    transports: {
      [base.id]: http(),
    },
  });

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <OnchainKitProvider>
            <Provider store={store}>
              <HelmetProvider>
                <ThemeContextProvider>
                  <LanguageProvider>
                    <RefreshContextProvider>
                      <ContractContextProvider>
                        <ModalProvider>{children}</ModalProvider>
                      </ContractContextProvider>
                    </RefreshContextProvider>
                  </LanguageProvider>
                </ThemeContextProvider>
              </HelmetProvider>
              <ToastContainer />
            </Provider>
          </OnchainKitProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default Providers;
